import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BackgroundImage from 'gatsby-background-image'
import CustomLink from './custom-link'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background:
      'linear-gradient(90deg, #00234B -1.81%, rgba(0, 28, 87, 0.00) 113.33%);',
    width: '100%',
    height: '537px',
    color: theme.palette.primary.contrastText,
    paddingTop: '117px',
    paddingBottom: '80px',
    marginTop: '64px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '77px',
      marginTop: '45px',
      justifyContent: 'center',
      background:
        'linear-gradient(90deg, #00234B -26.94%, rgba(0, 35, 87, 0) 150%);'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '0',
      width: '100%',
      height: '64px',
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down('sm')]: {
        height: '45px'
      }
    }
  }),
  heroWrapper: {
    maxWidth: '570px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '310px',
      textAlign: 'center',
      margin: '0 auto'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '260px',
    margin: '24px auto 0',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      maxWidth: '100%',
      margin: '32px 0 0'
    },
    '& a': {
      marginBottom: '16px',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        marginBottom: '0',
        marginRight: '0px',
        width: 'auto'
      }
    }
  },
  ctaButton: {
    marginLeft: '16px',
    marginTop: '0',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      marginTop: '16px',
      '& a': {
        width: '100%'
      }
    }
  },
  videoWrapper: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    padding: '0',
    overflow: 'hidden',
    zIndex: '-1',
    bottom: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    '&:before': {
      display: 'block',
      content: '""',
      paddingTop: '470px'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      background: 'rgba(15, 16, 19, 0.33)',
      zIndex: '100'
    },
    '& iframe': {
      position: 'absolute',
      top: '0',
      bottom: '100px',
      left: '0',
      width: '100%',
      height: '100%',
      border: '0',
      transform: 'scale(1.8)'
    }
  }
}))

const Video = ({ src }) => (
  <iframe
    src={src}
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
    title="PGA Coach"
  />
)

const Hero = ({
  image,
  mobileImage,
  title,
  description,
  actions,
  isVideoBG
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <BackgroundImage
      Tag="section"
      fluid={
        isMobile
          ? mobileImage.childImageSharp.fluid
          : image.childImageSharp.fluid
      }
    >
      {isVideoBG && (
        <Box className={classes.videoWrapper}>
          <Video src="https://player.vimeo.com/video/318557649?background=1&autoplay=1&loop=1&byline=0&title=0" />
        </Box>
      )}
      <Box className={classes.root} display="flex" flexDirection="column">
        <Container maxWidth="xl">
          <Box className={classes.heroWrapper}>
            <Typography variant="h2" gutterBottom>
              {title}
            </Typography>
            <Typography variant="subtitle2">{description}</Typography>
            {actions && (
              <Box className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  href="https://my.pga.com/pga-coach?utm_campaign=coachlp&utm_source=hero"
                  component={CustomLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Launch Business Tools
                </Button>
                <Box className={classes.ctaButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    href="https://pgacoach.lightspeedvt.com"
                    component={CustomLink}
                    target="_blank"
                    rel="noreferrer"
                    fullWidth={isMobile}
                  >
                    Access Coach Training
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </BackgroundImage>
  )
}

export default Hero
